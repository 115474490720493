// custom typefaces
import "typeface-montserrat"
import "typeface-montserrat-cyrillic"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

import "./src/css/@wordpress/block-library/build-style/style-old.css"
import "./src/css/@wordpress/block-library/build-style/theme.css"

// custom CSS styles
import "./src/css/style.css"

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
